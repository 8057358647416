export enum ENVs {
  'production' = 'production',
  'development' = 'development',
}

const NAME = 'NovaHealth';
const ENV =
  process.env.NODE_ENV === 'production' ? ENVs.production : ENVs.development;
const GTM_TAG = process.env.REACT_APP_GTM_TAG || 'GTM-59T6QR25';
const API_TOKEN =
  process.env.REACT_APP_API_TOKEN || '63728c20-b9d1-40b8-9d58-00dd8a641520';
const BASE_URL =
  process.env.REACT_APP_API_URL ||
  'https://wellness-a-team-effecto-funnel-api-sandbox.cl2.kilo.live/api/';
const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS || '';
const FIREBASE_REGISTER_URL =
  process.env.REACT_APP_FIREBASE_REGISTER_URL ||
  'https://us-central1-raising-dog-app-staging.cloudfunctions.net/register';
const FIREBASE_SHIPPING_ADDRESS =
  process.env.REACT_APP_SHIPPING_ADDRESSS ||
  'https://us-central1-panda-routine-staging.cloudfunctions.net/shippingAddress';

const FIREBASE_SHIP_BOOK =
  process.env.REACT_APP_SHIP_BOOK ||
  'https://us-central1-raising-dog-app-staging.cloudfunctions.net/shipDogBook';
const PATIENT_APP_BASE_URL = process.env.REACT_APP_PATIENT_APP_BASE_URL || '';
const HYROS_TOKEN = process.env.REACT_APP_HYROS_TOKEN || '';
const FIREBASE_MAILING =
  process.env.REACT_APP_FIREBASE_MAILING ||
  'https://us-central1-panda-routine-staging.cloudfunctions.net/sendEmail';
const GENERATE_EBOOK =
  process.env.REACT_APP_FIREBASE_GENERATE_EBOOK ||
  'https://us-central1-raising-dog-app-staging.cloudfunctions.net/eBookGenerationDog';

const REACT_APP_FIREBASE_USER_HAS_PAYED_URL =
  process.env.REACT_APP_FIREBASE_USER_HAS_PAYED_URL ||
  'https://us-central1-raising-dog-app-staging.cloudfunctions.net/userHasPaid';

const KLAVIYO_KEY = process.env.REACT_APP_KLAVYIO_KEY;

const GOOGLE_LOCATION_KEY =
  process.env.REACT_GOOGLE_LOCATION_KEY ||
  'AIzaSyBV7kkqQFnnHoHdM7MxEI4Qmg-ncRSwGVU';

const DUPLICATE_CHECK =
  process.env.REACT_DUPLICATE_CHECK ||
  'https://us-central1-raising-dog-app-staging.cloudfunctions.net/handleDuplicateBookOrder';

const PHYSICAL_ORDER_COLLECTOR =
  process.env.REACT_PHYSICAL_ORDER_COLLECTOR ||
  'https://us-central1-raising-dog-app-staging.cloudfunctions.net/storePhysicalBookOrder';

const FACEBOOK_PIXEL_ID = process.env.GATSBY_FACEBOOK_PIXEL_ID;

const FACEBOOK_API_ACCESS_TOKEN = process.env.GATSBY_FACEBOOK_API_ACCESS_TOKEN;

const FACEBOOK_API_VERSION = process.env.GATSBY_FACEBOOK_API_VERSION;

const TRACKING_ENABLED = true;

const CAPI_FUNCTION_URL = process.env.FIREBASE_CLOUD_FUNCTION_URL;

const WY_REGISTER_URL =
  process.env.FIREBASE_WY_REGISTER_URL ||
  'https://us-central1-walking-yoga-app-staging.cloudfunctions.net/registerInWeb';

const REACT_APP_FIREBASE_USER_HAS_PAID_URL_WY =
  process.env.REACT_APP_FIREBASE_USER_HAS_PAID_URL_WY ||
  'https://us-central1-walking-yoga-app-staging.cloudfunctions.net/userHasPaid';
const CLOUD_FUNCTION_URL_SC = process.env.FIREBASE_CLOUD_URL_SC;

const CLOUD_FUNCTION_URL_TT = process.env.FIREBASE_CLOUD_CAPI_URL_TT;

const BLOCKED_COUNTRIES = ['LT'];

const configs = {
  [ENVs.development]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
    FIREBASE_REGISTER_URL,
    FIREBASE_SHIPPING_ADDRESS,
    PATIENT_APP_BASE_URL,
    HYROS_TOKEN,
    FIREBASE_MAILING,
    REACT_APP_FIREBASE_USER_HAS_PAYED_URL,
    GENERATE_EBOOK,
    FIREBASE_SHIP_BOOK,
    KLAVIYO_KEY,
    GOOGLE_LOCATION_KEY,
    DUPLICATE_CHECK,
    PHYSICAL_ORDER_COLLECTOR,
    FACEBOOK_PIXEL_ID,
    FACEBOOK_API_ACCESS_TOKEN,
    FACEBOOK_API_VERSION,
    TRACKING_ENABLED,
    CAPI_FUNCTION_URL,
    WY_REGISTER_URL,
    REACT_APP_FIREBASE_USER_HAS_PAID_URL_WY,
    CLOUD_FUNCTION_URL_SC,
    CLOUD_FUNCTION_URL_TT,
    BLOCKED_COUNTRIES,
  },
  [ENVs.production]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
    FIREBASE_REGISTER_URL,
    FIREBASE_SHIPPING_ADDRESS,
    PATIENT_APP_BASE_URL,
    HYROS_TOKEN,
    FIREBASE_MAILING,
    REACT_APP_FIREBASE_USER_HAS_PAYED_URL,
    GENERATE_EBOOK,
    FIREBASE_SHIP_BOOK,
    KLAVIYO_KEY,
    GOOGLE_LOCATION_KEY,
    DUPLICATE_CHECK,
    PHYSICAL_ORDER_COLLECTOR,
    FACEBOOK_PIXEL_ID,
    FACEBOOK_API_ACCESS_TOKEN,
    FACEBOOK_API_VERSION,
    TRACKING_ENABLED,
    CAPI_FUNCTION_URL,
    WY_REGISTER_URL,
    REACT_APP_FIREBASE_USER_HAS_PAID_URL_WY,
    CLOUD_FUNCTION_URL_SC,
    CLOUD_FUNCTION_URL_TT,
    BLOCKED_COUNTRIES,
  },
};

export const config = configs[ENV];
